import React from 'react'

import { rem as px2rem } from '../../../utils/remUtils'

import styles from './CouponCardBoxWithColorfulBg.module.css'
import cx from '../../../utils/className'


const CouponCardBoxWithColorfulBg = props => {
  const {
    renderTopContent = () => null,
    renderBottomContent = () => null
  } = props

  const topStyle = {}
  const bottomStyle = {}

  const { backgroundColor, borderRadius, borderColor } = props

  if (backgroundColor) {
    topStyle.backgroundColor = backgroundColor
    bottomStyle.backgroundColor = backgroundColor
  }

  if (borderColor) {
    const borderStyle = `${px2rem(1)} solid ${borderColor}`

    topStyle.border = borderStyle
    topStyle.borderBottom = 'none'

    bottomStyle.border = borderStyle
    bottomStyle.borderTop = 'none'

  }

  if (borderRadius) {
    topStyle.borderRadius = `${px2rem(borderRadius)} ${px2rem(borderRadius)} 0 0`
    bottomStyle.borderRadius = `0 0 ${px2rem(borderRadius)} ${px2rem(borderRadius)}`
  }


  return (
    <div className={cx(styles.wrapper, props.className)}>
      <div className={styles.topContent}>
        <main style={topStyle}>{renderTopContent()}</main>
      </div>
      <div className={styles.separatorWrapper}>
        <div className={styles.separator} />
      </div>
      <div className={styles.bottomContent}>
        <main style={bottomStyle}>{renderBottomContent()}</main>
      </div>
    </div>
  )
}

export default CouponCardBoxWithColorfulBg